import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { Stack } from '../stack';
import { Text } from '../text';
import type { ReactNode } from 'react';

export const Field = ({
  label,
  isDisabled,
  isReadOnly,
  isOptional,
  description,
  error,
  inline,
  reserveErrorMessageSpace,
  children,
}: {
  label: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isOptional?: boolean;
  error?: string;
  description?: string;
  onValueChange?: (value: string | undefined) => void;
  reserveErrorMessageSpace?: boolean;
  inline?: boolean;
  children: ReactNode;
}) => {
  const isInvalid = !!error?.length;

  return (
    <FormControl
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      width='full'
      data-invalid={isInvalid}
    >
      <Stack gap='1'>
        <Flex direction={inline ? 'row' : 'column'} gap='1'>
          <Stack gap='0.5'>
            <FormLabel fontSize='sm' marginBottom='0' display='flex' gap='1'>
              <Text fontWeight='bold'>{label}</Text>{' '}
              {isOptional ? (
                <Text fontSize='xs' color='gray.300'>
                  (optional)
                </Text>
              ) : null}
            </FormLabel>
            {description && (
              <Text fontSize='xs' color='gray.500' paddingBottom='1'>
                {description}
              </Text>
            )}
          </Stack>
          {children}
        </Flex>
        {reserveErrorMessageSpace || isInvalid ? (
          <Box height={18}>
            {isInvalid && (
              <FormErrorMessage margin={0}>{error}</FormErrorMessage>
            )}
          </Box>
        ) : null}
      </Stack>
    </FormControl>
  );
};
